import React, { useState, useEffect } from 'react';
import rainSoundFile from './rain.ogg';

const RainSound = ({ play }) => {
    useEffect(() => {
        const audio = new Audio(rainSoundFile);
        audio.loop = true;

        if (play) {
            audio.play().catch(error => console.error("Error playing audio:", error));
        } else {
            audio.pause();
        }

        return () => {
            audio.pause();
            audio.currentTime = 0;
        };
    }, [play]);

    return null;
};

export default RainSound;
