import React from 'react';
import { AppBar, Toolbar, Typography } from '@mui/material';

const BottomAppBar = () => {
    return (
        <AppBar
            position="fixed"
            sx={{
                top: 'auto',
                bottom: '.5rem',
                backgroundColor: "transparent",
                boxShadow: "none",
                color: "white",
                height: "30px",
            }}
        >
            <Toolbar>
                <Typography
                    variant="caption"
                    sx={{
                        textAlign: "center",
                        flexGrow: 1,
                        color: "white",
                        fontWeight: "normal",
                        fontSize: "9px",
                    }}
                >
                    © 2024 BitesZilla. All rights reserved.
                </Typography>
            </Toolbar>
        </AppBar>
    );
};

export default BottomAppBar;
