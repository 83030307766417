// eslint-disable-next-line
import React, { useEffect, useState } from 'react';
import './App.css';
import Background from './images/wine.png';
import Splash from './Splash.js';
import { Box } from '@mui/material';
import YouTube from 'react-youtube';
import TopAppBar from './TopAppBar.js';
import BottomAppBar from './BottomAppBar.js';

const App = () => {
  const backgroundStyle = {
    backgroundImage: `url(${Background})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '100vh',
    width: '100%',
    position: 'relative',
  };

  const boxStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: '20px',
    background: 'rgba(255, 255, 255, 0.8)',
    borderRadius: '10px',
    textAlign: 'center',
    zIndex: 1,
  };

  const videoContainerStyle = {
    position: 'relative',
  };

  const [isVideoPlaying, setVideoPlaying] = useState(false);

  const opts = {
    height: '315',
    width: '560',
    playerVars: {
      autoplay: isVideoPlaying ? 1 : 0,
      controls: 1,
    },
  };

  useEffect(() => {
    // Your rain animation code can go here if needed
  }, []);

  return (
    <>
      <Box sx={{ position: 'absolute', zIndex: 1 }}>
        <Splash />
      </Box>

      <div style={backgroundStyle}>


        {/* Embedded YouTube Video in the Body */}
        <div style={boxStyle}>
          <div style={videoContainerStyle}>
            <YouTube videoId="TUADs-CK7vI" opts={opts} onPlay={() => setVideoPlaying(true)} />
          </div>
        </div>
         <TopAppBar />
         <BottomAppBar />
      </div>
    </>
  );
};

export default App;
