import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, IconButton } from '@mui/material';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import StopIcon from '@mui/icons-material/Stop';
import RainSound from './audio/RainSound.js';

const TopAppBar = () => {
    const [playSound, setPlaySound] = useState(false);

    const handleSoundToggle = () => {
        setPlaySound(!playSound);
    };

    return (
        <>
            <AppBar
                position="fixed"
                sx={{
                    backgroundColor: "transparent",
                    boxShadow: "none",
                    color: "white",
                }}
            >
                <Toolbar>
                    <Typography
                        variant="h1"
                        sx={{
                            textAlign: "left",
                            flexGrow: 1,
                            color: "white",
                            fontWeight: "bold",
                            fontSize: "30px",
                        }}
                    >
                        BitesZilla
                    </Typography>
                    <IconButton onClick={handleSoundToggle} color="inherit">
                        {playSound ? <StopIcon /> : <VolumeUpIcon />}
                    </IconButton>
                </Toolbar>
            </AppBar>
            <RainSound play={playSound} />
        </>
    );
};

export default TopAppBar;
